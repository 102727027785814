import React, { useState } from 'react';
import styles from './CharpointsBanner.module.css'; //styles

const CharpointsModule = (props) => {
  const [bags, setBags] = useState(props.numberOfBags); // Initial value set to 14

  const handleBagsChange = (e) => {
    console.log(e.target.value);
  };

  const total = bags * 10; // £10 per bag

  return (
    <div className={styles.charpointsContainer}>
      <div className={styles.charpointsHeader}>
        <div className={styles.charpointsTitle}><span className={styles.charpointsFontAwesome}>sack-dollar</span>CHARPOINTS</div>
        <div className={styles.charpointsPrice}><span className={styles.currency}>£</span>10<span className={styles.perBag}>/bag</span></div>
      </div>
      <div className={styles.charpointsBody}>
        For every bag you scan, get £10 off your next order!
      
        <div className={styles.charpointsTotal}>
          <span className={styles.totalText}>You're getting:</span>
          <div className={styles.charpointsCalculation}>
            {/* <input
              type="number"
              value={bags}
              onChange={handleBagsChange}
              className={styles.charpointsInput}
              min="0"
            /> */}
            <span className={styles.totalText2}>{props.numberOfBags}  x £10 = </span>£{props.numberOfBags * 10}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharpointsModule;