import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './Orders.module.css';
import helpers from '../../helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from '../../UserContext.js';


import TextInput from "../../components/TextInput.js";
import Button from "../../components/Button.js";
import Overlay from "../../components/Overlay.js";
import Selector from "../../components/Selector.js";
import ProductCounter from "../../components/subscription/ProductCounter.js";







function Orders(props) {
  const {user, setUser} = useContext(UserContext);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [displayScheduleOverlay, setDisplayScheduleOverlay] = useState(false);
  const [orderToUpdate, setOrderToUpdate] = useState(null);
  const [deliveries, setDeliveries] = useState(null);
  const [assignedDeliveryID, setAssignedDeliveryID] = useState("newDelivery");




  const [formData, setFormData] = useState({
    pincharBags: 0,
    pelletcharBags: 0,
    deliveryId: "",
    date: new Date()
  });

  const fetchUpcomingDeliveries = async () => {
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      }
    };
    console.log(user.backEndURL);
    let response = await fetch(user.backEndURL + "/orders", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse);
      // let newFarmers = [];
      // jsonResponse.data.forEach((farmer) => {
      //   let farmerDate = new Date(farmer.delivery_date)
      //   let dateString = farmerDate.getDay() + "/" + farmerDate.getMonth() + "/" + farmerDate.getYear();
      //   let newFarmer = {
      //     id: farmer._id,
      //     status: farmer.status,
      //     deliveryDate: dateString,
      //     pincharBags: farmer.products[0].amount,
      //     pelletcharBags: farmer.products[1].amount,
      //     weight: 2.2,
      //     volume: 8
      //   }
      //   newFarmers.push(newFarmer);
      // })

      setDeliveries(jsonResponse.data);
      console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
    } else {
      console.log("Fetch Subscription Failed...")
    }
  }

  

  useEffect(() => {
    console.log("Fetching Deliveries")
   
    fetchUpcomingDeliveries();
  },[user])

  const handleChange = (formField, value) => {
    setFormData({ ...formData, [formField]: value });

  };
  
  const updateBiocharQty = (pincharBags) => {
    setFormData({ ...formData, pincharBags: pincharBags, pelletcharBags: 0 });
  };

  const scheduleOrder = async () => {
    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify({
        products: [{_product: "651d47889b872e666d637d8f", amount: formData.pincharBags}],
        userId: props.farmer._id,
        deliveryId: assignedDeliveryID,
        deliveryDate: formData.date
      })
    };
    console.log("107")
    console.log(request.body)
    let response = await fetch(user.backEndURL + "/order", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      alert("Successfully Scheduled!");
      setDisplayScheduleOverlay(false);
      props.updateFarmerList();
    } else {
      alert("Something went wrong...")
    }
  }

  const renderScheduleOrder = () => {
    if(displayScheduleOverlay){
      console.log(user)
      // console.log(deliveries[0].orders[0]);
      let deliveriesData = [{name: "New Delivery", value: "newDelivery"}];
      if(deliveries !== null && deliveries.length !== 0){
        deliveries.forEach((delivery) => {
          console.log(delivery)
          let deliveryData = {
            name: delivery.delivery_id +  " (" + delivery.orders[0].products[0].amount + " bags)",
            value: delivery._id
          }
          deliveriesData.push(deliveryData);
        })
      }
      return(
        <Overlay>
          <div className={styles.scheduleOrderContainer}>
            <h2>New Order</h2>
            <div className={styles.datePickerContainer}>
              Delivery Date:
              {/* <DatePicker className={styles.datePicker} calendarClassName={styles.calendar} selected={formData.date} onChange={(date) => handleChange("date", date)} /> */}
              <input className={styles.datePicker} type="date" value={formData.date} onChange={(e) => handleChange("date", e.target.value)}/>
            </div>
          <div className={styles.productCounterContainer}>
            <ProductCounter name={"Pinchar"} price={"£330/Bag"} value={formData.pincharBags} handleChange={handleChange} fieldName={"pincharBags"}/>
          </div>
          <div className={styles.updateOrderRow}>
            <Selector name="Assigned Delivery" value={assignedDeliveryID} data={deliveriesData} onChange={(value) => setAssignedDeliveryID(value)}  labelStyle="top" placeholder="Select Delivery" color="white"/>
          </div>
          <Button name={"Confirm Order"} onPress={() => scheduleOrder()}/>
          <Button name={"Cancel"} color={"Error"} onPress={() => setDisplayScheduleOverlay(false)}/>
  
          </div>
        </Overlay>
      )
    }else{
      return null
    }
  }

  const updateOrder = async (isDelete) => {
    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify({
        orderId: orderToUpdate._id,
        userId: props.farmer._id, 
        status: isDelete ? "delete" : orderToUpdate.status
      })
    };
    let response = await fetch(user.backEndURL + "/order", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) { 
      alert("Successfully Updated!");
      setOrderToUpdate(null);
      props.updateFarmerList();
    } else {
      alert("Something went wrong...")
    }
    
  }

  const renderUpdateOrder = () => {
    if(orderToUpdate === null){
      return;
    }
    let deliveryDate = new Date(orderToUpdate.delivery_date);
    let selectorData = [
      {name: "Upcoming", value: "upcoming"},
      {name: "Delivered", value: "delivered"},
      {name: "Cancelled", value: "cancelled"}
    ]
    console.log(orderToUpdate)
    return(
      <Overlay>
        <div className={styles.scheduleOrderContainer}>
           <h2>Update Order</h2>
          <div className={styles.updateOrderStatus}></div>
          <div className={styles.updateOrderRow}>
           Order ID:{" " + orderToUpdate.order_id}
          </div>
          <div className={styles.updateOrderRow}>
           Delivery ID:{" " + orderToUpdate._delivery.delivery_id}
          </div>
          <div className={styles.updateOrderRow}>
           # of Bags:{" " + orderToUpdate.products[0].amount}
          </div>
          <div className={styles.updateOrderRow}>
            Delivery Date:{" " +deliveryDate.getDate() + "/" + (deliveryDate.getMonth()+1) + "/" + deliveryDate.getFullYear()}
          </div>
          <div className={styles.updateOrderRow}>
            <Selector name="Status" value={orderToUpdate.status} data={selectorData} onChange={(value) => setOrderToUpdate({...orderToUpdate, status: value})}  labelStyle="top" placeholder="Select Status" color="white"/>
          </div>
        <Button name={"Update Order"} onPress={() => updateOrder(false)}/>
        <Button name={"Delete Order"} color={"Error"} onPress={() => updateOrder(true)}/>
        <Button name={"Cancel"} color={"Coal"} onPress={() => setOrderToUpdate(null)}/>
        </div>
      </Overlay>
    ) 
  }

  const renderOrders = () => {
    console.log(deliveries);
    let filteredOrders = props.farmer._orders;//[];
    console.log(filteredOrders);
  //   if(selectedFilter === "all"){
  //     filteredOrders = orders;
  //   }else {
  //     filteredOrders = orders.filter(function (order) {
  //       return order.status === selectedFilter 
  //     }
  //   );
  // }

  
  
    if(filteredOrders.length > 0){
      return(
        <>
          {filteredOrders.map(order => {
            let deliveryDate = new Date(order.delivery_date);
            return(
              <tr className={styles.tableRow}>
                <td className={styles.dataBold}>#{order.order_id}</td>
                <td className={styles.dataBold}>#{order._delivery ? order._delivery.delivery_id :" n/a"}</td>
                <td><span className={styles.dataBold}>{renderStatus(order.status)}</span></td>
                <td><span className={styles.dataBold}>{deliveryDate.getDate() + "/" + (deliveryDate.getMonth()+1) + "/" + deliveryDate.getFullYear()} </span></td>
                <td><span className={styles.dataBold}>{order.products[0].amount} </span>bags</td>
                <td ><span className={styles.editIcon} onClick={() => {setOrderToUpdate(order)}}>edit</span></td>
              </tr>
            ) 
          })
        }
        </>
      )
    } else {
      return (
        <div className={styles.ordersPlaceholder}>
          No data to display...
        </div>
      )
    }
    
  }
  
  return (
    <div className={styles.container}>
      {renderScheduleOrder()}
      {renderUpdateOrder()}
      <div className={styles.ordersContainer}>
        <div className={styles.ordersListHeader}>Orders</div>
        <table className={styles.orderTable}>
          <tr className={styles.orderHeaderRow}>
            <th>ID</th>
            <th>Delivery ID</th>
            <th>Status</th>
            <th>Delivery Date</th>
            <th>Bags</th>
          </tr>
          {renderOrders()}
        </table>
        {/* <div className={styles.ordersListColumn}>
        Hello
      </div> */}
      </div>
      <div className={styles.buttonRow}>
        <div className={styles.buttonContainer}>
         <Button name={"Schedule New Order"} color={"Coal"} onPress={() => setDisplayScheduleOverlay(true)}/>
        </div>
      </div>


    </div>
  );
}

const renderStatus = (status) => {
  switch(status){
    case "delivered":
      return <td className={styles.statusDelivered}><span className={styles.statusIcon}>check-circle</span>Delivered</td>
    case "upcoming":
      return <td className={styles.statusUpcoming}><span className={styles.statusIcon}>arrow-circle-right</span>Upcoming</td>
    case "cancelled":
      return <td className={styles.statusCancelled}><span className={styles.statusIcon}>times-circle</span>Cancelled</td>
    default:
        return <td>N/A</td>
  }

}

export default Orders;
