import React from 'react';
import {Page, Text, Image, Document, View, StyleSheet, Font} from "@react-pdf/renderer";

import TruenoBd from "../fonts/Trueno/TruenoBlk.otf";
import BBBLogo from "./images/bbbLogoWhiteTransparentNoText.png";

Font.register({
    family: 'TruenoBd',
    src: TruenoBd
});

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%"
    },
    header: {
        width: "100%",
        height: "45px",
        padding: "4px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "black",
    },
    logo: {
        maxWidth: "30px",
    },
    logoLabel: {
        fontSize: "14px",
        fontFamily: "TruenoBd",
        color: "white"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        backgroundColor: "pink",
        color: "black",
        height: "300px",
        width: "2300px"
    }
});

const InvoiceBasic = (props) => {
    return(
        <Document>
            <Page size="A4">
                <View style={styles.container}>
                    <View style={styles.header}>
                        <Image src={BBBLogo} style={styles.logo}/>
                        <Text style={styles.logoLabel}>
                            BLACK BULL BIOCHAR
                        </Text>
                    </View>
                    
                    <View style={styles.content}>
                        <Text>
                            Invoice #{props.id} ssdsdsdsds
                        </Text>
                        <Text>John Doe </Text>
                            <Text>12 Redrup House</Text>
                           
                    </View>
                    
                </View>
            </Page>
        </Document>

    )

}

export default InvoiceBasic;