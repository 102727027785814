
import styles from './Sites.module.css';
import helpers from '../../helpers.js';
import {useState, useContext, useEffect, useRef} from 'react';

import { UserContext } from '../../UserContext.js';

import Module from "../../components/Module.js";
import TextInput from "../../components/TextInput.js";
import Selector from "../../components/Selector";
import Button from "../../components/Button.js";

import iconAhlstrom from "../../assets/images/iconAhlstrom.png";
import iconJenkinson from "../../assets/images/iconJenkinson.png";




function Sites(props) {
  const {user, setUser} = useContext(UserContext);
  const [charcodeAmount, setCharcodeAmount] = useState(0);
  const [weekSlots, setWeekSlots] = useState([]);
  const [weekSlotsBackup, setWeekSlotsBackup] = useState([]); //Used to reset values
  const [isEditing, setIsEditing] = useState(false)


  const fetchSites = async () => {
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      }
    };
    console.log(user)
    console.log(user.backEndURL);
    let response = await fetch(user.backEndURL + "/sites", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse);
      
      // return json
      // let newFarmers = [];
      // jsonResponse.data.forEach((farmer) => {
      //   let farmerDate = new Date(farmer.delivery_date)
      //   let dateString = farmerDate.getDay() + "/" + farmerDate.getMonth() + "/" + farmerDate.getYear();
      //   let newFarmer = {
      //     id: farmer._id,
      //     status: farmer.status,
      //     deliveryDate: dateString,
      //     pincharBags: farmer.products[0].amount,
      //     pelletcharBags: farmer.products[1].amount,
      //     weight: 2.2,
      //     volume: 8
      //   }
      //   newFarmers.push(newFarmer);
      // })

      // setFarmers(jsonResponse.data);
      console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
    } else {
      console.log("Fetch Subscription Failed...")
    }
  }

  useEffect(() => {

    let initSiteSlots = async () => {
      const renderWeekSlots = async () => {
          fetchSites();
          let today = new Date();
          // today.setDate(today.getDate() + 3);
          let from = new Date(today.getTime() - 21 *24*60*60*1000);
          let fromDaysUntilSunday = 7 - from.getDay();
  
          let to = new Date(today.getTime() + 65 *24*60*60*1000);
          let daysUntilSunday = 7 - today.getDay();
          let d = (to.getTime() - from.getTime())/ (24*60*60*1000);
      
          let nextSunday = new Date()
          nextSunday.setDate(today.getDate() + daysUntilSunday)
          let weekSlots = [];
          let j = 0;
          
          for(let i = (from.getTime() + fromDaysUntilSunday *24*60*60*1000 ); i < (to.getTime() + 7 * 24 * 60 * 60 * 1000); (i += 7 * 24 * 60 * 60 * 1000)){
            let startDate = new Date(i - 7 * 24 * 60 * 60 * 1000);
            startDate.setHours(23);
            startDate.setMinutes(59);
            startDate.setSeconds(59);
            startDate.setMilliseconds(999);
            let endDate = new Date(i)
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
            endDate.setMilliseconds(999);
            let weekSlot = {
              startDate: startDate,
              endDate: endDate,
              weekIndex: (j-3),
              weeklyProduction: 0,
              estProduction: 2,
              estProduction: 2,
              bagsDelivered: 4,
              currentStock: 12,
              forecastedStock: 5
            }
            weekSlots.push(weekSlot);
            j++;
            if (j > 20){
              break;
            }
          }
          setWeekSlots(weekSlots);
          setWeekSlotsBackup(weekSlots);
      }
      renderWeekSlots();
    }

    initSiteSlots();
  }, []) 
  
  
  const updateValue = (valueType, value, weekIndex) => {
    console.log(valueType);
    console.log(value);
    console.log(weekIndex)
    setWeekSlots(prevWeekSlots => {
        return prevWeekSlots.map((slot, i) => {
          if (i === weekIndex) {
            return { ...slot, [valueType]: value};
          }
          return slot;
        });
      });
  }

  const renderRowValues = (valueType) => {

    let header = "";
    let values = [];
    let isDark = false;

    switch(valueType){
      case "weeklyProduction":
        header = "Weekly Production";
        values = weekSlots.map((week, i) => {return(i > 2 ? "-" : week.weeklyProduction)});
        isDark = true;
        break;
      case "estProduction":
        header = "Est. Weekly Production";
        values = weekSlots.map((week, i) => {return(i < 3 ? "-" : week.estProduction)});
        isDark = false;
        break;
    case "bagsDelivered":
        header = "Bags Delivered";
        values = weekSlots.map((week) => week.bagsDelivered);
        isDark = true;
        break;
    case "currentStock":
        header = "Current Stock";
        values = weekSlots.map((week, i) => {return(i > 3 ? "-" :week.currentStock)});
        isDark = false;
        break;
    case "forecastedStock":
        header = "Forecasted Stock";
        values =  weekSlots.map((week, i) => {
          console.log("s");
          let forecastedStock = "-"
          if(i > 4){
            console.log(values)
             forecastedStock = weekSlots[i-2].currentStock + weekSlots[i-1].estProduction - weekSlots[i-1].bagsDelivered + weekSlots[i].estProduction - weekSlots[i].bagsDelivered;
          } else if (i > 3){
            forecastedStock = weekSlots[i-1].currentStock + weekSlots[i].estProduction - weekSlots[i].bagsDelivered;
          }
          return(i < 4 ? "-" : forecastedStock)
        });
        isDark = true;
        break;
    }


    return(
        <div className={helpers.clx(styles.row)}>
            <div className={styles.rowHeader}>
                {header}
            </div>
            <div className={helpers.clx(styles.rowContentContainer, isDark ? styles.rowDark : "")}>
                {values.map((value, index) => {
                return (
                  <input disabled={!isEditing} type={"text"} onChange={(event) => updateValue(valueType, event.target.value, index)} value={value} className={helpers.clx(styles.rowValue, index === 4 ? styles.rowValueCurrentWeek : "")}/>
                )
                })}
            </div>
        </div>
    )
  }
  

  const renderDateHeaders = () => {
    console.log(weekSlots)
    return(
        <div className={styles.headerDatesContainer}>
            {weekSlots.map((week) => {
                return (
                    <div className={styles.headerDateContainer}>
                      <div className={helpers.clx(styles.weekIndex)}>
                        {week.weekIndex}
                      </div>
                      <div className={helpers.clx(styles.date, week.weekIndex === 0? styles.weekIndexSelected : null)}>
                        {formatDate(week.startDate)}
                      </div>
                    </div>
                )
            })}
        </div>
    )
    
  }

  return (
    <div className={helpers.clx("contentGrid", styles.contentGrid)}>
        <Module name={"Pyrolysis Sites"} spanColumn={24} spanRow={2} height={"100%"}>
            <div className={styles.contentContainer}>
                {
                    isEditing ? 
                        <>
                            <div className={helpers.clx(styles.editIcon, styles.closeIcon)} onClick={() => setIsEditing(false)}>window-close</div>
                            <div className={helpers.clx(styles.editIcon, styles.confirmIcon)} onClick={() => setIsEditing(true)}>check-square</div>
                        </>
                    :
                        <div className={styles.editIcon} onClick={() => setIsEditing(true)}>pencil</div>
                }

                <div className={styles.siteContainer}>
                    <div className={styles.headerRow}>
                        <img src={iconAhlstrom} className={styles.headerIcon}/>
                        {renderDateHeaders()}
                    </div>
                    {renderRowValues("weeklyProduction")}
                    {renderRowValues("estProduction")}
                    {renderRowValues("bagsDelivered")}
                    {renderRowValues("currentStock")}
                    {renderRowValues("forecastedStock")}
                </div>
                <div className={styles.siteDivider}/>
                <div className={styles.siteContainer}>
                    <div className={styles.headerRow}>
                        <img src={iconJenkinson} className={styles.headerIcon}/>
                        {renderDateHeaders()}
                    </div>
                    {renderRowValues("weeklyProduction")}
                    {renderRowValues("estProduction")}
                    {renderRowValues("bagsDelivered")}
                    {renderRowValues("currentStock")}
                    {renderRowValues("forecastedStock")}
                </div>
            </div>
           
        </Module>
    </div>
  );
}


export default Sites;


function formatDate(date) {
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    
    return `${month} ${day}`;
  }