import React from 'react';
import {useState, useRef, useEffect, useContext} from "react";
import { UserContext } from "../UserContext.js";
import helpers from '../helpers.js';



import styles from './Charcode.module.css'; //styles
import QRCodeStyling from "qr-code-styling";

import logo from "../assets/images/bbbLogo.png";




/*

<img/>
<div className="squareClassAndA"/>
    BUTTON
        props:
            name: str, name and label of input
            placeholder: str, placeholder text
            labelStyle: default is on the left, top has the label above the input
            iconName: font awesome icon name, default is no icon
            value: input value (state) from parent
            onPress: setter from parent
            customStyle: TODO based on future requirements
*/
const Charcode = (props) => { const [fileExt, setFileExt] = useState("png");
    const {user, setUser} = useContext(UserContext);
    const ref = useRef(null);

    let qrCode = new QRCodeStyling({
        width: 600,
        height: 600,
        image:
        logo,
        dotsOptions: {
        color: "black",
        type: "rounded"
        },
        cornersSquareOptions: {
            color: "black",//"#34B61F",
            type: "extra-rounded"
        },
        cornersDotOptions: {
            color: "black"//"#B0E000"
        },
        backgroundOptions: {
            color: "#fff",
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 6,
            imageSize: 0.5
        }
    });

    useEffect(() => {
        qrCode.append(ref.current);
        qrCode.update({
            data: /*user.frontEndURL*//* "https://542b-137-22-191-187.ngrok-free.app" */  "https://dashboard.blackbullbiochar.com" + ""/*user.frontEndURL*/ + "/charcode/" + props.charcodeId
        });
    }, []);
 
    let str1 = props.charcodeId.substring()
    return(
        <div className={helpers.clx(styles.qrOuterBorder, props.isTooltip ? styles.qrOuterBorderHidden : "")}>
             {props.isTooltip ? 
                    null
                : 
                <div className={styles.qrBottomLabel}>
                    <div className={styles.scanToConfirm}>
                        SCAN TO CONFIRM
                    </div>               
                    <div className={styles.instructions}>
                        Open camera and scan code!
                    </div>
                </div>
             }
            
            <div className={styles.qrContainer}>
                <div ref={ref} className={styles.qrCode}/>
                <div className={styles.charcode}>
                    <span className={styles.cha}>CHA-</span>{props.charcodeId.substring(4,10)}
                </div>
            </div>
        </div>
    
    )
}

Charcode.defaultProps = {
    charcodeId: "CHA-000000",
    isTooltip: false
};

export default React.memo(Charcode);