
import styles from './FieldsScreen.module.css';
import helpers from './helpers.js';
import {useState, useContext, useCallback, useEffect, useRef} from 'react';
import { UserContext } from './UserContext.js';
import { GoogleMap, useJsApiLoader, DrawingManager } from '@react-google-maps/api';

import Nav from "./components/Nav.js";
import ScreenHeader from "./components/ScreenHeader.js";
import Module from "./components/Module.js";
import TextInput from "./components/TextInput.js";
import Button from "./components/Button.js";
import FAQ from "./components/support/FAQ.js";

import mapStyle from './assets/MapStyle.js';

const mapContainerStyle = {
  width: '700px',
  height: '500px',
  borderRadius: '20px',
  boxShadow: "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12)",
  borderRadius: "1.3rem"
};


//54.713801, -2.749613
const center = {
  lat: 54.713801,
  lng: -2.749613
};

const fieldsArray = [
  {
    id: "",
    status: "delivered",
    deliveryDate: "",
    pincharBags: "",
    pelletcharBags: "",
    weight: "",
    volume: ""
  }
]
const libraries = ['drawing'];




function FieldsScreen(props) {
  // const {user, setUser} = useContext(UserContext);
  // const [selectedFilter, setSelectedFilter] = useState("all");
  // const [fields, setFields] = useState(fieldsArray);

  // useEffect(() => {
  //   console.log("Fetching Fields")
  //   const fetchFields = async () => {
  //     const request = {
  //       method: "get",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + user.token,
  //       }
  //     };
  //     let response = await fetch(user.backEndURL + "/fields", request);
  //     let jsonResponse = await response.json();
  //     if (jsonResponse.success) {
  //       console.log(jsonResponse.data);
  //       let newFields = [];
  //       jsonResponse.data.forEach((field) => {
  //         let fieldDate = new Date(field.delivery_date)
  //         let dateString = fieldDate.getDay() + "/" + (fieldDate.getMonth()+1) + "/" + fieldDate.getFullYear();
  //         let newField = {
  //           id: field._id,
  //           status: field.status,
  //           deliveryDate: dateString,
  //           pincharBags: field.products[0].amount,
  //           pelletcharBags: 0,//field.products[1].amount,
  //           weight: Math.round(field.products[0].amount * 0.32 * 10)/10,
  //           volume: field.products[0].amount * 2
  //         }
  //         newFields.push(newField);
  //       })

  //       setFields(newFields);
  //       console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
  //     } else {
  //       console.log("Fetch Subscription Failed...")
  //     }
      
  //   }
  //   fetchFields();
  // },[]) 

  // const renderFields = () => {
  //   let filteredFields = [];
  //   if(selectedFilter === "all"){
  //     filteredFields = fields;
  //   }else {
  //     filteredFields = fields.filter(function (field) {
  //       return field.status === selectedFilter 
  //     }
  //   );
  // }

  // const renderStatus = (status) => {
  //   switch(status){
  //     case "delivered":
  //       return <td className={styles.statusDelivered}><span className={styles.statusIcon}>check-circle</span>Delivered</td>
  //     case "upcoming":
  //       return <td className={styles.statusUpcoming}><span className={styles.statusIcon}>arrow-circle-right</span>Upcoming</td>
  //     case "cancelled":
  //       return <td className={styles.statusCancelled}><span className={styles.statusIcon}>times-circle</span>Cancelled</td>
  //     default:
  //         return <td>N/A</td>
  //   }

  // }
 

//   return(
//     <>
//       {filteredFields.map(field => {
//         return(
//           <tr className={styles.tableRow}>
//             <td className={styles.dataBold}>#{field.id}</td>
//             {renderStatus(field.status)}
//             <td>{field.deliveryDate}</td>
//             <td><span className={styles.dataBold}>{field.pincharBags} </span>Bags</td>
//             <td><span className={styles.dataBold}>{field.pelletcharBags}</span> Bags</td>
//             <td><span className={styles.dataBold}>{field.weight} </span>tons</td>
//             <td><span className={styles.dataBold}>{field.volume}</span> m<span className={styles.exp}>3</span></td>
//           </tr>
//         ) 
//       })
//     }
//     </>
//   )
// }
//   return (
//     <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
//       <Nav goToScreen={props.goToScreen} currentScreen={"fields"}/>
//       <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
//         <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
//           <ScreenHeader name={"Fields"} content={"Browse through your biochar fields. Don’t hesitate to contact us if you have any questions!"}/>
//           <div className={"contentGrid"}>
//             <Module spanColumn={24} spanRow={5} height={"100%"}>
//               <div className={styles.headerRow}>
//                 <div onClick={() => setSelectedFilter("all")} className={helpers.clx(styles.headerFilter, selectedFilter === "all" ? styles.headerFilterSelected : "")}>
//                   All
//                 </div>
//                 <div onClick={() => setSelectedFilter("upcoming")} className={helpers.clx(styles.headerFilter, selectedFilter === "upcoming" ? styles.headerFilterSelected : "")}>
//                   Upcoming
//                 </div>
//                 <div onClick={() => setSelectedFilter("delivered")} className={helpers.clx(styles.headerFilter, selectedFilter === "delivered" ? styles.headerFilterSelected : "")}>
//                   Delivered
//                 </div>
//                 <div onClick={() => setSelectedFilter("cancelled")} className={helpers.clx(styles.headerFilter, selectedFilter === "cancelled" ? styles.headerFilterSelected : "")}>
//                   Cancelled
//                 </div>
//               </div>
//               <table className={styles.fieldTable}>
//                 <tr className={styles.fieldHeaderRow}>
//                   <th>Field ID</th>
//                   <th>Status</th>
//                   <th>Delivery Date</th>
//                   <th>Pinchar</th>
//                   <th>Pelletchar</th>
//                   <th>Weight</th>
//                   <th>Volume</th>
//                 </tr>
//                 {renderFields()}
//               </table>

//             </Module>
//           </div>
//         </div>
//       </div>
//     </div>
//   );


/*

{ isLoaded ? 
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <DrawingManager
          onPolygonComplete={onPolygonComplete}
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: ['polygon'],
            },
            polygonOptions: {
              editable: true,
              draggable: true,
            },
          }}
        />
      </GoogleMap>
     : null
    }

*/
const { isLoaded } = useJsApiLoader({
  id: 'google-map-script',
  googleMapsApiKey: 'AIzaSyBsqWOikm4ic-3u6A57JyvzXLq1fAc9_Hg',
  libraries,
});
const [map, setMap] = useState(null);
const [allPolygons, setAllPolygons] = useState([]);
const polygonRefs = useRef([]);
const onLoad = useCallback((mapInstance) => {
  setMap(mapInstance);
}, []);

const onUnmount = useCallback((mapInstance) => {
  setMap(null);
}, []);


const onPolygonComplete = (polygon) => {
  const path = polygon.getPath();
  const coordinates = path.getArray().map(coord => ({
    lat: coord.lat(),
    lng: coord.lng()
  }));
  const newPolygon = {
    name: "N/A",
    coordinates: coordinates
  }
  polygon.addListener('mouseover', () => {
    map.setOptions({ draggableCursor: 'pointer' }); // Change cursor to pointer
  });

  polygon.addListener('mouseout', () => {
    map.setOptions({ draggableCursor: 'crosshair' }); // Revert to default cursor
  });

  setAllPolygons((prevPolygons) => [...prevPolygons, newPolygon]);
  polygonRefs.current.push(newPolygon.coordinates);

  // axios.post('http://localhost:3000/savePolygon', coordinates)
  //   .then(response => {
  //     console.log(response.data.message);
  //   })
  //   .catch(error => {
  //     console.error('There was an error saving the polygon!', error);
  //   });

  // Add a right-click listener to each polygon to remove it
  window.google.maps.event.addListener(polygon, 'rightclick', () => {
    const index = polygonRefs.current.indexOf(polygon);
    if (index > -1) {
      polygon.setMap(null); // Remove from map
      polygonRefs.current.splice(index, 1); // Remove from refs
      setAllPolygons(polygonRefs.current.map(p => p.getPath().getArray().map(coord => ({
        lat: coord.lat(),
        lng: coord.lng()
      }))));
    }
  });

  // Add event listeners for editing vertices
  window.google.maps.event.addListener(path, 'set_at', () => {
    console.log('Vertex moved');
    // Handle vertex move event
  });

  window.google.maps.event.addListener(path, 'insert_at', () => {
    console.log('Vertex added');
    // Handle vertex add event
  });
  

};

return(
  <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
  <Nav goToScreen={props.goToScreen} currentScreen={"fields"}/>
  <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
    <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
      <ScreenHeader name={"Fields"} content={"Add, edit, and delete the fields you own. These are used to associate biochar usage on your farm"}/>
      <div className={helpers.clx("contentGrid", styles.contentGrid)}>
        <div>
        { isLoaded ? 
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={8}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              styles: mapStyle, // Apply the custom styles
              disableDefaultUI: true,          // ! to Disable all default UI controls
              draggable: true,                // ! to Disable dragging
              zoomControl: true,              // ! to Disable zooming
              scrollwheel: true,              // ! to Disable scroll wheel zooming
              disableDoubleClickZoom: true,    // ! to Disable double-click zoom
              draggableCursor: 'crosshair',    // Set cursor style to crosshair
            }}
          >
            <DrawingManager
              drawingMode="polygon" // Force the polygon drawing tool to be active
              onPolygonComplete={onPolygonComplete}
              options={{
                drawingControl: false,
                drawingControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: ['polygon'],
                  draggable: false,
                },
                polygonOptions: {
                  strokeColor:  '#B0E000', //'#34B61F',      // border) color
                  strokeWeight: 2,             // Stroke width in pixels
                  strokePattern: [10, 10],     // 10px dash, 10px gap                  strokeOpacity: 0.8,          // Stroke opacity (transparency)
                  fillColor: '#B0E000',        // fill color
                  fillOpacity: 0.15,           // Fill opacity (transparency)
                  editable: true,
                  draggable: false,
                },
              }}
            />
          </GoogleMap>
        : null
        }
        </div>
        
        <Button onPress={() => setAllPolygons([])}/>
        {/* <Module name={"User Details"} spanColumn={10} spanRow={2} height={"100%"}>
          <div/>
        </Module> */}
      </div>
        {
          allPolygons.map((polygon, index) => {
            console.log(polygon)
            return(
              <div>{polygon.coordinates.map((coordinates) => {
                return (
                  <div>
                    x: {coordinates.lat}, y: {coordinates.lng}
                  </div>
                )
              })}
              </div>
            );
          })
        }
    </div>
  </div>
</div>
  )

}

export default FieldsScreen;
