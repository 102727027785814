import {useState, useEffect, useRef} from "react";

import styles from './Selector.module.css'; //styles
import helpers from '../helpers.js';

import arrowGrey from "../assets/images/selectArrowGrey.png";
import arrowWhite from "../assets/images/selectArrowWhite.png";


/*

TODO: COAL VERSION
    SELECTOR
        props:
            name: str, name and label of selector
            placeholder: str, placeholder text
            labelStyle: default is on the left, top has the label above the selector
            iconName: font awesome icon name, default is no icon
            data: array of options with name/label and value [{option.value, option.name},{},...]
            value: selector value from parent. Gets matched with data, to display the right label (see displaySelectedOption())
            onChange: setter from parent
            customStyle: TODO based on future requirements
*/

function useOutsideAlerter(ref, collapse) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          collapse();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const Selector = (props) => {
    const [focused, setFocused] = useState(false);
    const [transitioning, setTransitioning] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => expand(false));

    const expand = (expanding) => {
        if(!expanding){
            setTransitioning(true);
            setFocused(false);
            return;
        }
        setTransitioning(true);
        setFocused(!focused)
        setTimeout(() => {setTransitioning(false)}, 100)
    }

    const renderOptions = () => {
        return(
            <div className={helpers.clx(styles.optionsContainer, focused ? "": styles.optionsContainerCollapsed)}>
                {props.data.map((option) => {
                    console.log(option); console.log(option.value); console.log(option.name); return <div className={styles.optionRow} onClick={() => !transitioning ? props.onChange(option.value) : null}>{option.name}</div>
                })}
            </div>
        )
    }

    const displaySelectedOption = () => {
        const selectedOption = props.data.filter(function (option) {
            return option.value === props.value;
        })
        
        if(selectedOption.length > 0){
            return  selectedOption[0].name;
        }
    }

    return(
        <div ref={wrapperRef}  className={helpers.clx(styles.mainContainer, props.labelStyle=== "top" ? styles.mainContainerLabelTop: "", props.labelStyle=== "none" ? styles.mainContainerNoLabel: "")}>
            {props.labelStyle === "none" ? null : 
                <div className={styles.LabelContainer}>
                    <div className={styles.LabelIcon}>
                    {props.iconName}
                    </div>
                    {props.name}
                </div>
            }
            <div className={helpers.clx(styles.selectorContainer, focused ? "" : styles.selectorContainerCollapsed, props.labelStyle=== "none" ? styles.selectorContainerNoLabel: "")} onClick={() => {expand(!focused)}} style={props.customStyle}>
                <img src={props.color === "white" ? arrowGrey : arrowWhite} className={helpers.clx(styles.arrow, focused ? styles.arrowReversed : "")}/>
                <div className={helpers.clx(styles.selectedOption, props.value === "" ? styles.selectedOptionPlaceholder : "")}>{props.value !== "" ? displaySelectedOption() : props.placeholder}</div>
                <div className={focused ? styles.selectedOptionLineFocused : styles.selectedOptionLine}/>
                {renderOptions()}
            </div>
        </div>
    )
}

Selector.defaultProps = {
    name: 'SelectorName',
    placeholder: "Change Placeholder Prop", 
    labelStyle: 'left', //top left none
    onChange: console.log("Change onChangeProp"), //set setState from parent
    color: "white", // white coal
    iconName: "", //font-awesome 5 icon name
  };

export default Selector;